.playlists-page {
    min-height: calc(100vh - 9rem);
    margin-top: 9rem;
    padding-bottom: 12rem;

    background-image: url("../../assets/images/background.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left right;

    display: flex;
    align-items: center;
    justify-content: center;

    .title {
        text-align: center;
        font-size: 4.8rem;
        font-weight: 600;
        background: linear-gradient(90deg, #FFF 0%, #7C7C7C 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .device-info {
        margin: 3rem 0;

        color: rgba(234, 233, 233, 0.70);
        text-align: center;
        font-size: 2.4rem;
        font-weight: 300;

        display: flex;
        flex-direction: column;
        gap: 1rem;

        span {
            color: #EAE9E9;
        }
    }

    .add-btn-container {
        display: flex;
        justify-content: center;

        button {
            max-width: 66.5rem;
            width: 100%;
        }
    }
}

@media screen and (max-width: 768px) {
    .playlists-page {
        .title {
            font-size: 3.6rem;
        }

        .device-info {
            font-size: 2rem;
        }
    }
}