.social-icons {
    position: fixed;
    right: 2%;
    bottom: 5%;
    z-index: 999999;

    display: flex;
    flex-direction: column;
    gap: 1rem;

    a {
        img {
            width: 4rem;
            border-radius: 50%;
            animation: pulse 2s infinite;
            cursor: pointer;
        }
    }
}

@keyframes pulse {
    0% {
        transform: scale(1);
        box-shadow: 0 0 0 0 #1b0b3b;
    }

    50% {
        transform: scale(1.1);
        box-shadow: 0 0 15px 5px #1b0b3b;
    }

    100% {
        transform: scale(1);
        box-shadow: 0 0 0 0 #1b0b3b;
    }
}