.content {
    text-align: center;

    .title {
        color: #EAE9E9;
        font-size: 2rem;
        font-weight: 300;
        margin: 2rem 0 4rem;
    }

    .btns {
        margin: 0 auto;
        display: flex;
        justify-content: center;
        gap: 2rem;

        button,
        .btn {
            width: max-content;
            padding: 0.5rem 2rem;
            margin: 0 !important;
            border-radius: 3.5rem;
            font-size: 2rem;
            cursor: pointer;
            border-color: transparent;
            display: flex;
            align-items: center;
            justify-content: center;

            &.cancel-btn {
                background: #1B1A1C;
                color: #F1F1F1;

                &:hover {
                    opacity: 0.9;
                }
            }

            &.ok-btn {
                background: #F1F1F1;
                color: #030106;

                &:hover {
                    opacity: 0.9;
                }
            }
        }

    }
}