.packages {
    width: 100%;
    height: 24.6rem;
    margin-bottom: 3rem;
    display: flex;
    justify-content: center;
    gap: 1.4rem;

    .package {
        min-width: 32rem;
        padding: 3rem;
        border-radius: 24px;
        border: 1px solid #BFBFBF;
        background: #1B1A1C;
        color: #EAE9E9;
        font-size: 1.8rem;
        font-weight: 400;

        cursor: pointer;

        transition: background 0.3s;

        &.active {

            background: #F2F2F2;
            color: #3D3D3D;

            .schedule-price-block {
                .schedule {
                    color: #3D3D3D;
                }
            }

            .schedule-type {
                color: #F1F1F1;
                background: linear-gradient(90deg, #4C1663 0%, #7704F2 117.18%);
            }
        }

        .top {
            display: flex;
            justify-content: space-between;
        }

        .name {
            min-width: 12.4rem;
            width: max-content;
            margin-bottom: 2rem;
            padding: 1rem;

            border-radius: 3.5rem;
            background: #9865FF;

            text-transform: uppercase;

            font-size: 14px;
            font-weight: 500;
            color: #FFF;

            display: flex;
            justify-content: center;
            align-items: center;
        }

        .schedule-price-block {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: space-between;
            gap: 1.2rem;

            .schedule {
                font-size: 2.4rem;
                font-weight: 500;
                text-transform: capitalize;
            }

            .price {
                font-size: 3rem;
                color: #9865FF;
                font-weight: 800;
                text-transform: uppercase;
            }
        }

        .schedule-type {
            width: 100%;
            margin-top: 4rem;
            padding: 1rem 0;

            display: flex;
            justify-content: center;
            align-items: center;

            border-radius: 3.5rem;
            background: linear-gradient(90deg, #4C1663 0%, #7704F2 117.18%);
            background: #FFF;
            color: #18191A;
            text-transform: capitalize;
        }
    }
}

@media screen and (max-width: 1000px) {
    .packages {
        justify-content: flex-start;
        overflow: auto;

        &::-webkit-scrollbar {
            display: none;
        }

        .package {
            width: 100%;
        }
    }
}