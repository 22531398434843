.footer {
    background: radial-gradient(72.24% 102.45% at 48.88% 83.55%, #151229 0%, #030106 100%);

    .footer-content {
        display: flex;
        justify-content: space-between;
        gap: 2rem;
        margin: 4.3rem auto;

        .logo {
            display: none;
        }

        .menu-list {
            font-size: 1.8rem;
            font-weight: 300;
            display: flex;
            gap: 5.2rem;

            transition: all 0.3s linear;

            .menu-item {
                cursor: pointer;
                position: relative;

                a {
                    color: #fff;
                }

                &.active {
                    font-weight: 600;
                }
            }
        }

        .social-links {
            margin-top: 2rem;
            display: flex;
            gap: 1.1rem;

        }

        .contact-us-form {
            .contact-us-title {
                margin-bottom: 2rem;
                color: #B0B0B0;
                font-size: 2rem;
                font-weight: 600;
            }

            .bottom {
                display: none;
            }
        }
    }


    .footer-bottom {
        padding: 3rem 0;
        background: #302D40;

        display: flex;
        justify-content: space-between;
        align-items: center;

        color: #FFF;
        font-size: 1.6rem;
        font-weight: 400;

        .footer-bottom-content {

            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 1.6rem;

            a {
                color: #FFF;
                font-size: 1.6rem;
                font-weight: 400;
            }
        }
    }
}

.social-icon-img {
    width: 3rem;
    height: 3rem;
}

@media screen and (max-width:752px) {
    .footer {
        .footer-content {
            flex-direction: column;

            .logo {
                display: block;
                margin: auto;
            }

            .menu-list {
                flex-direction: column;
                align-items: center;
                gap: 2rem;
            }

            .social-links {
                display: none;
            }

            .contact-us-form {
                margin: 0 auto;
                text-align: center;

                .bottom {
                    display: flex;
                    justify-content: center;
                    gap: 1.5rem;
                    margin-top: 3.8rem;
                }
            }
        }
    }
}