.playlists {
    max-width: 129rem;
    width: 85%;
    margin: 0 auto;
    margin-top: 6rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2.5rem;

    .container {
        position: relative;

        .playlist-item {
            padding: 1.2rem 2rem;
            border-radius: 42px;
            border: 0.5px solid #BFBFBF;
            background: #1B1A1C;
            color: #9D9D9D;
            font-size: 2rem;

            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 1.6rem;
            position: relative;
            z-index: 2;

            .name {
                color: #F1F1F1;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .url-container {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 1.6rem;
                width: 80%;

                .url {
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;

                }

                .edit-delete {
                    display: flex;
                    gap: 1.6rem;

                    div {
                        cursor: pointer;
                    }
                }

                .dots {
                    line-height: 1;
                    cursor: pointer;
                }
            }
        }

        .action-dropdown {
            width: 8rem;
            height: 6.1rem;

            position: absolute;
            right: -8rem;
            top: 0;

            border-radius: 19px;
            border: 0.5px solid #BFBFBF;
            background: #1B1A1C;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: .8rem;

            transform: translateX(-113%);
            z-index: -1;
            transition: transform 0.3s ease-in-out, z-index 0.3s ease-in-out;

            &.show {
                z-index: 1;
                transform: translateX(13%);

                .action-btn {
                    cursor: pointer;
                    transition: stroke 0.3s ease;

                    &:hover {
                        svg {
                            path {
                                stroke: #9865FF !important;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 824px) {
    .playlists {
        width: 100%;
    }
}