.current-page {
    min-height: calc(100vh - 9rem);
    margin-top: 9rem;
    padding: 6rem 0;
    background: radial-gradient(72.24% 102.45% at 48.88% 83.55%, #151229 0%, #030106 100%);

    display: flex;
    justify-content: center;
    align-items: center;

    .current-news {
        height: 40rem;
        overflow: hidden;
        padding: 4rem;
        border-radius: 2rem;
        border: 1px solid #666;
        background: rgba(255, 255, 255, 0.04);
        color: #FFF;
        font-size: 2rem;

        display: flex;
        gap: 3.5rem;

        img {
            max-width: 32rem;
            border-radius: 3.1rem;
        }

        .news-content {
            overflow: auto;

            &::-webkit-scrollbar {
                display: none;
            }

            .time {
                font-size: 1.5rem;
                display: block;
            }

            .title {
                padding: .5rem;
                margin-top: .8rem;
                margin-bottom: 2.4rem;
                font-size: 2.4rem;
                border-radius: 14px;
                background: rgba(232, 232, 232, 0.28);
                display: inline-block;
            }

            .description {
                font-weight: 300;
            }
        }
    }

    .subtitle {
        margin-top: 6rem;
        margin-bottom: 2rem;
        font-size: 3.6rem;
        font-weight: 600;
        background: linear-gradient(90deg, #FFF 0%, #7C7C7C 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}

@media screen and (max-width:830px) {
    .current-page {

        .slide-news,
        .current-news {
            flex-direction: column;
            height: auto;
            padding: 2rem;
            gap: 2rem;
            font-size: 1.6rem;

            img {
                display: block;
                margin: auto;
                max-width: 50%;
            }

            .news-content {
                text-align: center;

                .time {
                    font-size: 1.2rem;
                }

                .title {
                    font-size: 1.8rem;
                    margin: 1rem auto;
                }

                .description {
                    text-align: start;
                }
            }
        }

        .subtitle {
            font-size: 2.4rem;
        }
    }
}