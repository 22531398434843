.contact-us-page {
    min-height: calc(100vh - 9rem);
    margin-top: 9rem;
    padding: 3rem 0;

    background: radial-gradient(72.24% 102.45% at 48.88% 83.55%, #151229 0%, #030106 100%);

    .title {
        text-align: center;
        font-size: 3.8rem;
        font-weight: 600;
        background: linear-gradient(90deg, #FFF 0%, #7C7C7C 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }


    .content-container {
        margin-top: 4rem;
        padding: 8rem 6rem;
        border-radius: 20px;
        border: 2px solid rgba(10, 13, 23, 0.05);
        background: rgba(255, 255, 255, 0.04);
        backdrop-filter: blur(190px);

        display: flex;
        justify-content: space-between;
        gap: 4rem;

        .form {
            width: 50%;
            max-width: 42.6rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .description {
                color: #FFF;
                font-size: 1.6rem;
                font-weight: 400;
                margin-bottom: 4.1rem;
            }

            button {
                width: 100%;
                margin-top: 1.4rem;
            }
        }

        .contacts-wrapper {
            width: 50%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 10rem;

            .contacts {
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                gap: 4rem;

                font-size: 2rem;
                font-weight: 400;

                a {
                    color: #FFF;
                }

                .contact {
                    display: flex;
                    gap: 1rem;
                }
            }
        }
    }
}

@media screen and (max-width:960px) {
    .contact-us-page {
        .content-container {
            flex-direction: column;
            align-items: center;
            gap: 4rem;

            .form {
                width: 100%;
            }

            .contacts-wrapper {
                width: 100%;
                align-items: center;
                gap: 4rem;
            }
        }
    }
}

@media screen and (max-width:647px) {
    .contact-us-page {
        .content-container {
            padding: 4rem 3rem;

            .form {
                .description {
                    margin-bottom: 2.1rem;
                }
            }

            .contacts-wrapper {
                img {
                    width: 100%;
                }
            }
        }
    }

}