.slider-container {
    width: 100%;
    overflow: hidden;

    .slider {
        overflow: auto;
        display: flex;
        gap: 2rem;
        scroll-behavior: smooth;

        &::-webkit-scrollbar {
            display: none;
        }

        .slide-news {
            min-width: 100rem;
            width: 100%;

            height: 40rem;
            overflow: hidden;
            padding: 4rem;
            border-radius: 2rem;
            border: 1px solid #666;
            background: rgba(255, 255, 255, 0.04);
            color: #FFF;
            font-size: 2rem;

            display: flex;
            gap: 3.5rem;

            img {
                max-width: 32rem;
                border-radius: 3.1rem;
            }

            .news-content {
                overflow: auto;

                &::-webkit-scrollbar {
                    display: none;
                }

                .time {
                    font-size: 1.5rem;
                }

                .title {
                    width: max-content;
                    padding: .5rem;
                    margin-top: .8rem;
                    margin-bottom: 2.4rem;
                    font-size: 2.4rem;
                    border-radius: 14px;
                    background: rgba(232, 232, 232, 0.28);
                }

                .description {
                    font-weight: 300;
                }
            }
        }
    }
}

.arrows {
    margin-top: 2rem;
    display: flex;
    justify-content: flex-end;
    gap: .5rem;

    div {
        margin-right: 1rem;
        cursor: pointer;

        &.disbaled {
            cursor: not-allowed;
            opacity: .5;
        }
    }
}