.sidebar {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    background: rgba(0, 0, 0, 0.34);
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    opacity: 0;
    z-index: -1;
    transition: opacity 0.1s ease;

    &.show {
        z-index: 999;
        opacity: 1;
    }

    .sidebar-content {
        width: 0;
        height: 0;
        overflow: hidden;

        position: absolute;
        right: 0;
        top: 0;

        background: radial-gradient(77.54% 105.72% at 46.85% 76.83%, #151229 0%, #030106 100%);
        transition: width 0.3s ease, height 0.5s ease, overflow 0.1s ease;

        &.show {
            width: 32rem;
            height: 46.1rem;
        }

        .top-block {
            padding-top: 1.1rem;
            padding-left: 5.6rem;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .close {
                cursor: pointer;
            }
        }

        .menu-list {
            margin-left: 2rem;
            padding: 0 1.6rem 0 0;

            font-size: 1.8rem;
            color: #C7C7C7;
            margin-top: 3.1rem;

            .menu-item {
                cursor: pointer;
                position: relative;
                padding: 1rem 1.6rem;

                display: flex;
                align-items: center;
                justify-content: space-between;

                a {
                    display: inline-block;
                    width: 100%;
                    color: #C7C7C7;
                }

                &.active {
                    a {
                        color: #fff;
                    }

                    transition: all 0.3s;
                }
            }

            .contact-us-btn {
                width: 100%;
                display: flex;
                justify-content: center;

                button {
                    width: 100%;
                }
            }
        }


    }
}