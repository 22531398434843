.section {
    padding: 10rem 0;
    overflow: hidden;
    background: url("../../../assets/images/home-section-bg-2.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center right;

    &.second-bg {
        background: url("../../../assets/images/home-section-bg-1.png");
        background-position: left center;
    }

    .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 3rem;

        .texts {
            .title {
                font-size: 2.8rem;
                font-weight: 600;
                background: linear-gradient(90deg, #FFF 0%, #7C7C7C 100%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }

            .text {
                color: #BDBDBD;
                font-size: 1.6rem;
                font-weight: 300;
            }
        }

        .images {
            width: 100%;
            margin: auto;

            display: flex;
            align-items: center;
            gap: 1.5rem;

            overflow: auto;

            .img {
                width: 50%;
                min-width: 32.3rem;
                border-radius: 1rem;
            }

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }
}