.modal-content {
    .modal-title {
        font-size: 1.5rem;
        font-weight: 600;
        margin-bottom: 2rem;
        color: #EAE9E9;
        text-align: center;
        font-size: 3rem;
    }

    .tabs {
        padding: 0 .5rem;
        width: 95%;
        margin: 0 auto;
        margin-bottom: 3rem;
        color: #EAE9E9;
        font-size: 1.6rem;

        display: flex;
        justify-content: space-between;
        gap: 1rem;

        position: relative;

        &::after {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            background-color: #EAE9E9;
            position: absolute;
            bottom: 0rem;
            left: 0;
        }

        .tab {
            padding: 1rem;
            text-align: center;
            cursor: pointer;
            position: relative;
            transition: color 0.3s;

            &.active {
                color: #9940F7;

                &::after {
                    width: 100%;
                    transition: width 0.3s;
                }
            }

            &::after {
                content: '';
                display: block;
                width: 0;
                height: 1px;
                background-color: #9940F7;
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: 2;
            }
        }
    }

    .protected-checkbox {
        margin-bottom: 2rem;
    }

    button {
        height: 4rem;
        margin-top: 0 !important;
    }
}

.err-message-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;
}

@media screen and (max-width: 768px) {
    .modal-content {
        .tabs {
            font-size: 1.3rem;

            .tab {
                padding: 0.6rem;
            }
        }
    }
}