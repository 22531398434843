.partner-page {
    min-height: calc(100vh - 9rem);
    margin-top: 9rem;
    padding: 6rem 0;
    background: radial-gradient(72.24% 102.45% at 48.88% 83.55%, #151229 0%, #030106 100%);

    .wrapper {
        max-width: 95.4rem;
        width: 95%;
        margin: 0 auto;

        .title {
            margin: 2rem 0;
            font-size: 3rem;
            font-weight: 600;
            background: linear-gradient(90deg, #FFF 0%, #7C7C7C 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        .text {
            color: #ccc;
            font-size: 1.7rem;
        }
    }
}