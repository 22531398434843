.modal-mask {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    z-index: -1;
    transition: opacity 0.3s;
    // transition-delay: 0.3s;

    &.show {
        z-index: 9999;
        opacity: 1;
    }

    .modal {
        max-width: 56.6rem;
        width: 95%;
        padding: 3rem 2rem;

        background-image: url("../../assets/images/background.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        border-radius: 8px;
        border: 1.817px solid rgba(181, 118, 245, 0.29);
        background: #010101;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;

        position: relative;

        transform: scale(0.6);
        transition: transform 0.3s;
        // transition-delay: 0.3s;

        &.show {
            transform: scale(1);
        }

        .close-icon {
            position: absolute;
            right: 1rem;
            top: 1rem;
            cursor: pointer;
        }

        .icon {
            margin-bottom: 3rem;
            text-align: center;
        }

        .message {
            color: #F4F3F3;
            font-size: 2rem;
            font-weight: 300;
            text-align: center;
        }

        button {
            margin: 3rem auto 0;
        }

        .close-btn {
            button {
                height: 3.5rem;
            }
        }
    }
}