.message-open {
    margin-left: 1.4rem;
    font-size: 1.2rem;
    overflow: hidden;
    height: 2rem;
    transition: height 0.1s;

    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.message-close {
    transition: height 0.1s;
    overflow: hidden;
    height: 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.success-text {
    color: #4ca846;
}

.error-text {
    color: #dc3545;
}