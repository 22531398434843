@import '../../styles/index.scss';

.header {
    height: 9rem;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
    padding: 1.9rem 0;

    display: flex;
    justify-content: center;
    align-items: center;

    color: #fff;
    background-color: $primary-color;

    .content-block {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .menu-list {
            font-size: 1.8rem;
            font-weight: 300;

            display: flex;
            gap: 5.2rem;

            transition: all 0.3s linear;

            .menu-item {
                cursor: pointer;
                position: relative;

                a {
                    color: #fff;
                }

                &.active {
                    font-weight: 600;
                    transition: all 0.3s;

                    &::after {
                        width: 100%;
                    }
                }

                &::after {
                    content: '';
                    position: absolute;
                    bottom: -.3rem;
                    left: 0;
                    width: 0;
                    height: 2px;
                    background-color: #fff;
                    transition: width 0.3s ease;
                }

                &:hover {
                    &::after {
                        width: 100%;
                    }
                }
            }
        }

        .right-block {
            display: flex;
            align-items: center;
            gap: 3rem;

            button {
                height: 4rem;
                text-wrap: nowrap;
            }
        }

        .burger-menu {
            display: none;
            cursor: pointer;
        }
    }
}

@media screen and (max-width: 1000px) {
    .header {
        .content-block {

            .right-block,
            .menu-list {
                display: none;
            }

            .burger-menu {
                display: block;
            }
        }
    }
}