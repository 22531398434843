@import "../../styles/index.scss";

@keyframes fromTop {
    0% {
        transform: translateY(-5rem);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes fromBottom {
    0% {
        transform: translateY(5rem);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes toRight {
    0% {
        transform: translateX(-15rem);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes toLeft {
    0% {
        transform: translateX(15rem);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.home-header-section {
    margin-top: 9rem;
    color: #fff;
    text-align: center;

    background: radial-gradient(72.24% 102.45% at 48.88% 83.55%, #151229 0%, #030106 100%);


    .title {
        color: #FFF;
        font-size: 6.4rem;
        font-weight: 600;
        letter-spacing: -0.941px;
        text-transform: capitalize;
        margin-bottom: 3rem;
        animation: fromTop 1s ease;
    }

    .text,
    .description {
        color: #EAE9E9;
        font-size: 2rem;
        font-weight: 300;
        line-height: 3rem;
        max-width: 85.4rem;
        margin-top: 1.8rem;
        animation: toRight 1s ease;
        margin: auto;
    }

    .text {
        animation: toLeft 1s ease;
    }

    .buttons {
        margin-top: 3.1rem;
        margin-bottom: 3rem;

        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: 1.4rem;

        animation: fromBottom 2s ease;

        button {
            transition: all 0.3s ease;

            &:hover {
                transform: translateY(-1rem);
            }
        }
    }

    .header-image {
        width: 100%;
        height: 100%;
        max-height: 58.6rem;
    }
}

.download-apps-section {
    min-height: 326px;
    padding: 6.2rem 0;
    background: url("../../assets/images/apps-background.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left center;

    display: flex;
    justify-content: center;
    align-items: center;

    .apps-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .download-title {
            margin-bottom: 8rem;
            font-size: 3.6rem;
            font-weight: 400;
            line-height: 4.68rem;
            letter-spacing: -0.08rem;
            background: linear-gradient(90deg, #FFF 0%, #7C7C7C 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            text-transform: capitalize;
        }

        .apps-container {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            gap: 1.9rem;

            color: #868686;
            font-size: 1.8rem;
            font-weight: 400;
            letter-spacing: -0.0685rem;

            .download-app-block {
                width: 28.6rem;
                padding: 1.9rem 2rem;
                border-radius: 10.964px;
                border: 1.371px solid #685F72;

                display: flex;
                flex-direction: column;
                justify-content: space-between;

                .app-title {
                    color: #9865FF;
                    font-size: 2.6rem;
                    font-weight: 600;
                    letter-spacing: -.1096rem;
                }

                .app-download-file-btn {
                    width: 24.6rem;
                    border-radius: 2.1rem;
                    margin-top: 2rem;
                    padding: .2rem 8.3rem .2rem 8.2rem;
                    background: #FFF;
                    color: #18191A;
                    font-size: 1.6rem;
                    font-weight: 700;
                    line-height: 3.4rem;

                    display: flex;
                    justify-content: center;
                    align-items: center;

                }
            }
        }
    }
}

.section {
    height: 65rem;
    overflow-x: hidden;

    background: url("../../assets/images/home-section-bg-2.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left center;

    display: flex;
    justify-content: space-between;
    align-items: center;

    &.second-bg {
        background: url("../../assets/images/home-section-bg-1.png");
    }

    .img {
        width: 35%;
        height: 37.9rem;
        object-fit: contain;
        opacity: 0;
        transition: transform 0.5s, opacity 0.5s;
    }

    &.show {
        img {
            opacity: 1;
        }

        .img-left {
            transform: rotate(-4.88deg) translateX(-9rem);
        }

        .img-right {
            transform: rotate(4.88deg) translate(9rem);
        }
    }

    .section-texts {
        max-width: 47.4rem;
        color: #BDBDBD;
        text-align: center;
        font-size: 1.8rem;
        font-weight: 300;

        .section-title {
            font-size: 3.6rem;
            font-weight: 600;
            letter-spacing: -0.08rem;
            background: linear-gradient(90deg, #FFF 0%, #7C7C7C 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            margin-bottom: 3.4rem;
        }
    }
}

.latest-news-section {
    padding: 7rem 0;
    background: radial-gradient(72.24% 102.45% at 48.88% 83.55%, #151229 0%, #030106 100%);

    .news-section-title {
        text-align: center;
        margin-bottom: 8rem;
        font-size: 4.8rem;
        font-weight: 600;
        text-wrap: nowrap;
        background: linear-gradient(90deg, #FFF 0%, #7C7C7C 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    .news-container {
        width: 100%;
        overflow: auto;

        &::-webkit-scrollbar {
            width: 0;
            display: none;
        }

        .news-list {
            margin: auto;
            width: max-content;

            display: flex;
            gap: 3.4rem;

            .single-news {
                width: 27rem;

                .news-img {
                    width: 100%;
                    height: auto;
                    border-radius: 1.9rem;
                }

                .added-on {
                    color: #CCC;
                    font-size: 2rem;
                    font-weight: 400;
                    text-wrap: nowrap;
                }

                .time {
                    color: #FFF;
                    font-size: 2rem;
                    font-weight: 400;
                }

                .news-title {
                    color: #FFF;
                    font-size: 2.4rem;
                    font-weight: 600;
                    text-transform: capitalize;
                    text-overflow: ellipsis;

                    overflow: hidden;
                    white-space: nowrap;
                }
            }
        }
    }
}

@media screen and (max-width:430px) {

    .home-header-section {
        .title {
            font-size: 5rem;
        }

        .text,
        .description {
            font-size: 1.7rem;
        }
    }
}