@import "./index.scss";

@import url('https://fonts.googleapis.com/css2?family=Readex+Pro:wght@160..700&display=swap');

*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-size: 62.5%; //10rem
}

body {
    line-height: 1.5;
    font-family: "Readex Pro";
    background-color: $primary-color;
}

a {
    text-decoration: none;
}

ul {
    list-style: none;
}

//global wrapper class for all components
.wrapper {
    max-width: 192rem;
    width: 89.7%;
    margin: 0 auto;
}

@media screen and (max-width:1040px) {
    .wrapper {
        width: 95%;
    }
}