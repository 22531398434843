@import "../../styles/index.scss";

.dropdown {
    position: relative;
    transition: all .3s;
    cursor: pointer;
    min-width: 70px;
    width: 100%;

    &.in-sidebar {
        &::after {
            content: "▾";
            color: #fff;
            position: absolute;
            right: 0;
            top: -.3rem;
            right: 0;
        }

        .dropdown-toggle {
            font-size: 1.8rem;
            color: #C7C7C7;
        }

        &:hover {
            .dropdown-menu {
                width: 100%;
                height: 8rem;
                overflow: auto;
            }
        }
    }

    .dropdown-toggle {
        font-size: 1.2rem;
        border: none;
        overflow: unset;
        background-color: transparent;
        color: #fff;
        cursor: pointer;

        display: flex;
        align-items: center;
        gap: .7rem;
    }

    .lang-flag,
    .active-lang-flag {
        width: 2.1rem;
    }

    &:hover {
        .dropdown-menu {
            height: inherit;
            opacity: 1;
            overflow: auto;

            .dropdown-item {
                opacity: 1;
            }
        }
    }

    .dropdown-menu {
        height: 0;
        opacity: 0;
        overflow: hidden;
        font-size: 1.2rem;
        background: radial-gradient(72.24% 102.45% at 48.88% 83.55%, #151229 0%, #030106 100%);
        transition: height .3s;

        position: absolute;
        top: 3rem;
        left: -1rem;
        z-index: 9;

        .dropdown-item {
            padding: .5rem 1rem;
            color: #f1f1f1;
            cursor: pointer;

            display: flex;
            align-items: center;
            gap: .7rem;

            transition: background-color .5s;

            &:hover {
                background-color: rgba(255, 255, 255, .2);
            }
        }
    }
}