.button {
    padding: 1.3rem 1.9rem;
    color: #F1F1F1;
    font-size: 2rem;
    font-weight: 400;
    text-transform: capitalize;
    text-align: center;
    cursor: pointer;
    border: none;
    border-radius: 2.4rem;
    box-shadow: 0px 0.602px 0.602px -1.25px rgba(0, 0, 0, 0.18), 0px 2.289px 2.289px -2.5px rgba(0, 0, 0, 0.16), 0px 10px 10px -3.75px rgba(0, 0, 0, 0.06);

    background: linear-gradient(90deg, #4C1663 0%, #7704F2 117.18%);
    transition: background .3s;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;

    // &::before {
    //     content: "";
    //     width: 100%;
    //     height: 100%;
    //     position: absolute;
    //     left: 0;
    //     top: 0;
    //     background: linear-gradient(90deg, #4C1663 0%, #7704F2 117.18%);
    //     opacity: 1;
    //     transition: opacity .3s;
    //     border-radius: 2.4rem;
    // }

    // &::after {
    //     content: "";

    //     width: 100%;
    //     height: 100%;

    //     position: absolute;
    //     left: 0;
    //     top: 0;
    //     z-index: -1;

    //     background: linear-gradient(90deg, #4C1663 -50.92%, #9940F7 57.71%);

    //     opacity: 0;
    //     transition: opacity .3s;
    //     border-radius: 2.4rem;
    // }

    &.white {
        padding: 1.2rem 1.6rem;
        font-size: 1.8rem;
        font-weight: 500;
        color: #171717;
        background: #FFF;

        &:hover {
            color: #F1F1F1;
            background: linear-gradient(90deg, #4C1663 0%, #7704F2 117.18%);
        }
    }

    &.grey {
        border: .1rem solid #685F72;
        background: #1B1A1C;

        &:hover {
            border: .1rem solid #A2A2A2;
            background: #650CB7;
        }
    }

    &:hover {
        background: linear-gradient(90deg, #4C1663 -50.92%, #9940F7 57.71%);
    }

    &:disabled {
        // background: #A2A2A2;
        opacity: .5;
        cursor: not-allowed;
    }
}