.activation-page {
    width: 100%;
    min-height: calc(100vh - 9rem);
    margin-top: 9rem;
    padding: 1.5rem;
    background-image: url("../../assets/images/background.png");
    background-repeat: no-repeat;
    background-position: left;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;

    .title {
        text-align: center;
        font-size: 4.8rem;
        font-weight: 600;
        background: linear-gradient(90deg, #FFF 0%, #7C7C7C 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-bottom: 4.5rem;
    }

    .description {
        color: #EAE9E9;
        text-align: center;
        font-size: 1.8rem;
        font-weight: 300;
        max-width: 102.2rem;
        margin: 0 auto;
    }

    .page-content {
        max-width: 80.6rem;
        margin: 5rem auto;

        .privacy-text-section {
            color: #A4A4A4;
            font-size: 1.5rem;
            font-weight: 300;

            a {
                color: #A4A4A4;
            }

            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 3rem;
        }

    }

    .activate-by-voucher-wrapper {
        display: flex;
        justify-content: flex-end;

        .activate-by-voucher {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            gap: 1rem;

            .voucher-text {
                color: #A4A4A4;
                font-size: 1.6rem;
                font-weight: 300;
                max-width: 36rem;
                min-width: 28rem;
            }

            button {
                height: 4rem;
            }
        }
    }
}

.voucher-modal {
    text-align: center;

    .title-voucher-activation {
        color: #EAE9E9;
        font-size: 3rem;
        font-weight: 600;
        margin-bottom: 1.5rem;
    }

    .message-text {
        display: flex;
        justify-content: center;

        span {
            font-size: 1.6rem;
        }
    }

    button {
        height: 4rem;
    }
}

// -- payment methods buttons styles START --

.parcelado-usa,
.tazapay,
.coinbase-button,
.pixi-payment-button,
.cmi-payment,
.stripe-button {
    min-width: 32rem;
    width: 75%;
    margin: 0 auto;
    padding: 1rem;
    margin-top: 1rem;

    border-radius: .6rem;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    background-color: #014cec;
    color: #fff;
    font-weight: 500;
    font-size: 1.8rem;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;

}

.cmi-payment {
    background: white;
    color: red;
}

.stripe-button {
    background: white;
    color: #6459f6;
    font-weight: 700;
}

.parcelado-usa {
    background-color: #002776;
}

.tazapay {
    background-color: #103747;
}

.pixi-payment-button {
    background-color: #636363;
}

.paypal-btn-container,
.stripe-form-container {
    min-width: 32rem;
    width: 75%;
    margin: 0 auto;
    margin-top: 10px;

    .loading {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .stripe-form {

        .stripe-form-submit-button {
            display: block;
            width: 100%;
            padding: 1rem 2rem;
            margin: 2rem auto;
            font-weight: 700;
            font-size: 1.8rem;
            border-radius: .6rem;
            border-color: transparent;
            background-color: #6459f6;
            color: white;
            cursor: pointer;
        }
    }
}

// -- payment methods buttons styles END --