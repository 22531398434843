.news-page {
    min-height: calc(100vh - 9rem);
    margin-top: 9rem;
    background: radial-gradient(72.24% 102.45% at 48.88% 83.55%, #151229 0%, #030106 100%);

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    .title {
        text-align: center;
        font-size: 4.8rem;
        font-weight: 600;
        background: linear-gradient(90deg, #FFF 0%, #7C7C7C 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-bottom: 8rem;
    }
}

.news-wrapper {
    padding: 1rem;
    width: 100%;
    overflow: hidden;

    .news-container {
        max-width: 87.8rem;
        width: 100%;
        margin: 0 auto;

        display: flex;
        align-items: center;
        gap: 3.4rem;

        overflow: auto;

        &::-webkit-scrollbar {
            display: none;
        }

        .single-news {
            width: 27rem;
            min-width: 20rem;

            .news-img {
                width: 100%;
                height: auto;
                border-radius: 1.9rem;
            }

            .added-on {
                color: #CCC;
                font-size: 2rem;
                font-weight: 400;
                text-wrap: nowrap;
            }

            .time {
                color: #FFF;
                font-size: 2rem;
                font-weight: 400;
            }

            .news-title {
                color: #FFF;
                font-size: 2.4rem;
                font-weight: 600;
                text-transform: capitalize;
                text-overflow: ellipsis;

                overflow: hidden;
                white-space: nowrap;
            }
        }
    }

    .arrows {
        margin-top: 2rem;
        display: flex;
        justify-content: flex-end;
        gap: .5rem;

        display: none;

        div {
            margin-right: 1rem;
            cursor: pointer;

            &.disbaled {
                cursor: not-allowed;
                opacity: .5;
            }
        }
    }
}

@media screen and (max-width:741px) {
    .news-page {
        .title {
            font-size: 3.6rem;
        }
    }

    .news-wrapper {
        .news-container {
            justify-content: flex-start;

            .single-news {
                width: 27rem;
                min-width: unset;
            }
        }

        .arrows {
            display: flex;
        }

    }
}