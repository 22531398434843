.reseller-page {
    min-height: calc(100vh - 9rem);
    margin-top: 9rem;
    padding: 2rem 0;
    background-image: url("../../assets/images/background.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    display: flex;
    align-items: center;
    justify-content: center;

    .title {
        font-size: 4.8rem;
        font-weight: 600;
        letter-spacing: -0.8px;
        background: linear-gradient(90deg, #FFF 0%, #7C7C7C 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        text-align: center;
    }

    .subtitle {
        color: #EAE9E9;
        font-size: 2.4rem;
        font-weight: 300;
        display: block;
        text-align: center;
        max-width: max-content;
        margin: 0 auto;
    }

    .form-content {
        color: #EAE9E9;
        max-width: 80.6rem;
        width: 100%;
        margin: 4rem auto 0;

        button {
            width: 82%;
            margin: 0 auto;
        }

        .twain {
            display: flex;
            justify-content: space-between;
            gap: 2rem;
        }

        .message-container {
            display: flex;
            justify-content: center;
            margin-bottom: 2rem;

            span {
                font-size: 1.6rem;
            }
        }

        .form-bottom-section {
            margin: 2rem auto;

            align-items: center;
            color: #9D9D9D;
            font-size: 1.7rem;

            .agree-text {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 1rem;
            }

            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 2rem;
        }

        .login-text {
            color: #9D9D9D;
            font-size: 1.7rem;
            font-weight: 400;
            text-align: center;
            margin-top: 2rem;

            a {
                color: #FFF;
                font-weight: 600;
            }
        }
    }
}

.custom-dropdown {
    height: 4.2rem;
    position: relative;
    width: 100%;
    font-size: 1.4rem;

    &::after {
        content: "▾";
        position: absolute;
        top: 1.2rem;
        right: 1.4rem;
    }

    .dropdown-label {
        width: inherit;
        display: flex;
        align-items: center;
        gap: .8rem;
        padding: 1.2rem 1.4rem;
        border-radius: 3.4rem;
        border: .1rem solid rgba(255, 255, 255, 0.20);
        background: rgba(255, 255, 255, 0.05);
        color: rgba(255, 255, 255, 0.50);
        height: 4.2rem;

        &.error {
            border: .1rem solid #dc3545;
        }

        img {
            width: 2.5rem;
            height: 1.5rem;
            object-fit: contain;
        }
    }

    .dropdown-options {
        height: 0;
        overflow: hidden;
        transition: height 0.3s linear;
        position: absolute;
        border-radius: 5px;
        background: #100e13;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
        color: rgba(255, 255, 255, 0.50);

        &.show {
            height: 16rem;
            position: absolute;
            top: 5.2rem;
            left: 0;
            right: 0;
            z-index: 9;
            border: .1rem solid rgba(255, 255, 255, 0.20);
            overflow-y: auto;
        }

        .dropdown-option {
            padding: .5rem;
            padding-left: 1.4rem;
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: .8rem;

            .flag {
                width: 2.5rem;
                height: 1.5rem;
                object-fit: contain;
            }

            &:hover {
                background: rgba(255, 255, 255, 0.2);
            }
        }
    }
}

@media screen and (max-width:480px) {
    .reseller-page {
        .title {
            font-size: 3.6rem;
        }

        .subtitle {
            font-size: 1.8rem;
        }

        .form-content {
            .form-bottom-section {
                .container-block {
                    font-size: 1.3rem;
                }
            }
        }
    }
}

@media screen and (max-width:750px) {
    .reseller-page {
        .form-content {
            .form-bottom-section {
                justify-content: center;
            }
        }
    }
}