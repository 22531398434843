.input-container,
.phone-input {
    width: 100%;
    height: 6.2rem;

    position: relative;

    .eye-icon {
        position: absolute;
        right: 1.4rem;
        top: 1rem;
        cursor: pointer;
    }

    &.error {
        transition: border .3s;

        input,
        textarea {
            border: .1rem solid #dc3545;
        }
    }

    textarea,
    input {
        width: inherit;
        padding: 1.2rem 1.4rem;
        border-radius: 3.4rem;
        border: .1rem solid rgba(255, 255, 255, 0.20);
        background: rgba(255, 255, 255, 0.05);
        color: #fff;
        display: block;
        font-size: 1.4rem;

        &::placeholder {
            color: rgba(255, 255, 255, 0.50);
        }

        &:focus {
            outline: none;
        }

        &:disabled {
            cursor: not-allowed;
        }
    }

}

.textarea {
    height: 13rem;

    textarea {
        height: 83%;
        resize: none;
        border-radius: 1.7rem;
    }
}

label {
    text-wrap: nowrap;
    color: #9D9D9D;
    font-size: 1.6rem;
}

.phone-input {
    width: 100%;
    height: 4.2rem;

    position: relative;

    .eye-icon {
        position: absolute;
        right: 1.4rem;
        top: 1rem;
        cursor: pointer;
    }

    input {
        width: inherit;
        padding: 1.2rem 1.4rem;
        border-radius: 3.4rem;
        border: .1rem solid rgba(255, 255, 255, 0.20);
        background: rgba(255, 255, 255, 0.05);
        color: #fff;
        display: block;
        font-size: 1.4rem;

        &::placeholder {
            color: rgba(255, 255, 255, 0.50);
        }

        &:focus {
            outline: none;
        }
    }
}

.m3u8-file-input {
    width: 100%;
    height: 6.2rem;

    input[type="file"] {
        height: 100%;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 1.5rem;
        cursor: pointer;
        padding: .4rem 1.1rem;
    }

    .file-txt {
        position: relative;
        width: inherit;
        height: 4.2rem;
        padding: 1.2rem 1.4rem;
        border-radius: 3.4rem;
        border: .1rem solid rgba(255, 255, 255, 0.20);
        background: rgba(255, 255, 255, 0.05);
        color: rgba(255, 255, 255, 0.50);
        display: block;
        font-size: 1.4rem;

        &.error {
            border: .1rem solid #dc3545;
        }
    }
}