.privacy-policy-page {
    padding: 3.4rem 1rem;
    color: #BDBDBD;
    font-size: 1.8rem;
    margin-top: 9rem;
    min-height: calc(100vh - 9rem);
    background: radial-gradient(72.24% 102.45% at 48.88% 83.55%, #151229 0%, #030106 100%);

    .title {
        text-align: center;
        font-size: 3.6rem;
        font-weight: 400;
        margin-bottom: 3.4rem;
        letter-spacing: -0.8px;
        background: linear-gradient(90deg, #FFF 0%, #7C7C7C 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
}