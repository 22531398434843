.page {
    min-height: calc(100vh - 9rem);
    margin-top: 9rem;
    padding-bottom: 5rem;
    background-image: url("../../assets/images/background.png");
    background-repeat: no-repeat;
    background-position: left center;
    background-size: cover;

    display: flex;
    align-items: center;
    justify-content: center;

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .title {
            text-align: center;
            font-size: 4.8rem;
            font-weight: 600;
            background: linear-gradient(90deg, #FFF 0%, #7C7C7C 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        .text {
            text-align: center;
            color: #EAE9E9;
            font-size: 2.4rem;
            font-weight: 300;
            margin: 4rem 0;
            max-width: 45.8rem;
        }

        .img {
            width: 50%;
        }
    }
}

@media screen and (max-width: 768px) {
    .page {
        margin-bottom: 0;

        .content {
            .title {
                font-size: 3.6rem;
            }

            .text {
                font-size: 1.8rem;
                margin: 2rem 0;
            }

            .img {
                width: 80%;
            }
        }
    }
}