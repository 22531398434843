.payment-success-page {
    min-height: calc(100vh - 9rem);
    margin-top: 9rem;
    padding: 1.1rem;

    display: flex;
    justify-content: center;
    align-items: center;

    background: radial-gradient(72.24% 102.45% at 48.88% 83.55%, #151229 0%, #030106 100%);

    .message-container {
        max-width: 74.1rem;
        width: 100%;
        padding: 9rem 1rem;
        border-radius: 8px;
        border: 1.817px solid rgba(181, 118, 245, 0.29);
        background: #110E21;
        text-align: center;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .title {
            color: #F4F3F3;
            font-size: 3.6rem;
            font-weight: 500;
            margin: 3rem 0;
        }

        .message {
            max-width: 60rem;
            color: #F4F3F3;
            font-size: 2rem;
            font-weight: 300;
        }
    }
}