.login-page {
    min-height: calc(100vh - 9rem);
    margin-top: 9rem;
    background-image: url("../../assets/images/background.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: left right;

    display: flex;
    align-items: center;
    justify-content: center;

    .content {
        max-width: 80.6rem;

        .title {
            margin-bottom: 15rem;

            text-align: center;
            font-size: 4.8rem;
            font-weight: 600;
            background: linear-gradient(90deg, #FFF 0%, #7C7C7C 100%);
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }

        button {
            width: 75%;
            margin: 4.7rem auto;
        }
    }
}


.login-form {

    form {
        width: 100%;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        .error-message {
            margin-top: 1rem;
        }

        button {
            margin-top: 1.5rem !important;
        }
    }
}

@media screen and (max-width:480px) {
    .login-page {

        .content {
            .title {
                font-size: 3.6rem;
                margin-bottom: 10rem;
            }
        }
    }
}