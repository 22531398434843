.modal-text {
    margin-bottom: 3rem;
    color: #EAE9E9;
    font-size: 2rem;
    text-align: center;

}

.err-message-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 3rem;
}

.btns-container {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    gap: 2rem;

    button,
    .btn {
        width: max-content;
        padding: 0.5rem 2rem;
        margin: 0 !important;
        border-radius: 3.5rem;
        font-size: 2rem;
        cursor: pointer;
        border-color: transparent;
        display: flex;
        align-items: center;
        justify-content: center;

        &.cancel-btn {
            background: #1B1A1C;
            color: #F1F1F1;

            &:hover {
                opacity: 0.9;
            }
        }

        &.yes-btn {
            background: #F1F1F1;
            color: #030106;

            &:hover {
                opacity: 0.9;
            }
        }
    }

}